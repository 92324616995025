.lang-popup{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index: 2000;
    display: flex;
    background: #00000050;
    display: none;
    align-items: center;
    justify-content: center;
    &.active {
        display: flex;
    }

    .title h4 {
        font-size: 1.4rem;
    }

    .lang-popup-dialog {
        background: #fff;
        padding: 15px;
        border-radius: 5px;
        width: calc(100% - 30px);
        max-width: 800px;
        max-height: calc(100vh - 30px);
        overflow-y: auto;
    }

    .lang-label {
        color: #777;
        font-size: .8rem;
        text-transform: uppercase;
        position: relative;
        &.active{
            // font-weight: bold;
            // color: $bg-header;
        }
        &.active::after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: rgb(8, 161, 46);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(calc(100% + 8px), -50%);
            border-radius: 50%;
        }
        img {
            margin-right: 5px;
        }
        
    }
}
