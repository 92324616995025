.preload * {
    transition: none !important;
}

body {
    position: relative;
    background: $bg-main;
    min-height: 100vh;
}

body.preload:after{
    content: "";
    background: #fff;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.form-control {
    background: #f0f0f0 !important;
    border: 1px #e0e0e0 solid !important;

/*
    background: #e6f9ff !important;
    border: 1px #b8dce6 solid !important;
*/
    padding: 3px 10px !important;
    height: 40px;
    line-height: 34px;
}
.input-group-text {
    line-height: 30px;
    padding: 2px 12px !important;
    background: #f5f5f5;
    //color:#fff;
}

label {
    line-height: 36px;
    display: inline-block;
    margin-bottom:0;
}

input:focus,
select:focus,
textarea:focus {
    outline: none !important;
    box-shadow: none !important;
}

.form-control::placeholder {
    color: #bbb;
    opacity: 1;
}
.form-control[disabled] {
    background: #fafafa !important;
    border: 1px #f0f0f0 solid !important;
}

.input-group {
    border-radius: 0.25rem;
}


.form-control.is-invalid,
.input-group.is-invalid {
    border: 1px #dc3545 solid !important;
}

.i-save {
    display: inline-block;
    //background: #444;
    //color:$bg-header;    
}

.jBox-Confirm-button-submit {
    background: $bg-header !important;
}

.form-group.languages {
    .form-control, .input-group-append, .input-group-prepend {
        cursor: pointer;
    }
    &:focus {
        outline: none;
    }
}
.system-language {
    display: inline-block;
    margin-right: 10px;
}
.default-language {
    color: #aaa;
    font-style: italic;
    font-size: 0.9rem;
    /*
    .form & {
        font-size: .8rem;        
    }
    */
}

.form-control.textarea,
textarea.form-control{
    height: 80px;
    overflow-y: auto;
    line-height: 1.5rem;
    padding: 8px 10px  !important;
}

.test {
    border: 1px red solid;
}

