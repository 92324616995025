.table {
    width: 100%;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    border-style: hidden;

    td,
    th {
        padding: 6px 8px;
    }

    &.no-padding-td td {
        padding: 0;
    }

    td {
        border-bottom: 1px $color-border-cell solid;
    }

    tr td {
        background: $bg-1-table;
    }

    &:not(.no-alternate) tr:nth-child(2n) td {
        background: $bg-2-table;
    }

    th {
        background-color: $bg-header;
        color: #fff;
        font-size: .8rem;
        padding: 6px;
    }

    &:not(.no-hover) tr:hover td {
        background: #ddd !important;
    }

    .footer td,
    .footer th {
        background: #444;
    }

    .td.is-wide,
    th.is-wide {
        width: 100%;
    }

    th.tools,
    td.tools {
        width: 1%;
    }

    thead th,
    thead td {
        border: none;
    }

    td:not(:last-child),
    th:not(:last-child) {
        border-right: 1px #ccc solid;
    }

    thead td:not(:last-child),
    thead th:not(:last-child) {
        border-right: 1px lighten($bg-header, 10) solid;
    }



    .actions {
        text-align: center;
        white-space: nowrap;

        .btn {
            cursor: pointer;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            font-size: 14px;
            padding: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: #333;
            color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            &.delete {
                background: red;
            }
        }
    }

    tr.ghost td {
        background: #f5f5f5;
        opacity: 0.5;

    }

}

.card-list {
    border-top: $border-top-height $color-border-top solid;
    margin-top: 30px;
    padding-top: 20px;

    .card-tool {
        width: 36px;
        height: 36px;
        background: #ccc;
        color: $bg-header;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top: -$border-top-height / 2;
        cursor: pointer;
        transform: translateY(-50%);

        &:hover {
            transform: translateY(-50%) scale(1.2);
        }
    }

    .card-filters {
        right: 20px;
        i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .actions {
        padding: 0;
        display: inline-flex;

        .action {
            display: inline-block;
            width: 26px;
            height: 26px;
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $bg-header;
            border-radius: 50%;

            &:hover {
                text-decoration: none;
                transform: scale(1.2);
                background-color: #bbb;
            }

            &.action-delete {
                color: red;
            }
        }
    }

    .filters {
        padding-bottom: 30px;
        overflow: hidden;
        display: none;
    }
    
    &.filters-active .filters {
        display: block;
    }

    &.filters-active {
        .fa.down{
            display: none;
        }
        .fa.up{
            display: inline-block;
        }
    }

    &:not(.filters-active) {
        .fa.down{
            display: inline-block;
        }
        .fa.up{
            display: none;
        }
    }

    .card-body {
        padding-bottom: 0;
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
        background: #fff;
        border: none;
    }

    .form-label {
        color: $bg-header;
        font-weight: bold;
        font-size: .8rem;
        text-transform: uppercase;
    }

    .filter-actions {
        padding: 0 5px;
    }

}

.sort-field {
    user-select: none;
    vertical-align: top;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: lighten($bg-header, 10);
    }

    .sorting {
        color: $color-sorter;
        display: block;
        text-align: center;
        height: 18px;
        font-size: 14px;
    }
}

.active-yes{
    color:rgb(17, 209, 65);
}

.active-no {
    color:rgb(219, 104, 104);
}