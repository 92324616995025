footer {
  background: $bg-footer;
  color: #fff;
  height: $height-footer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
  font-size: .8rem;
}