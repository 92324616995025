.main {
    padding-left: 0;
    padding-top: $height-header;
    min-height: 100vh;
    transition: padding-left 0.2s;
    body.nav-active & {
        padding-left: $width-navigation;
        @media screen and (max-width: 600px) {
            padding-left: 0;
        }
    }
}

.right-side {
    min-height: calc(100vh - #{$height-header});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    width: 100%;
    padding: 0;
    .content-header {
        position: sticky;
        top:$height-header;
        background: #fff;
        height: $height-title;
        width: 100%;
        border-bottom: 1px #eeeeee solid;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        z-index: 1000;
        h1,h2,h3,h4,h5,h6 {
            font-size: 24px;
        }
    }
    .content-body {
        padding: 15px;
    }
    .content-footer {

    }
}

