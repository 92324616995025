.login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../images/login_bg.jpg");
    font-size: 1rem;

    .login-card {
        max-width: 350px;
    }

    .logo {
        color: #fff;
        font-size: 2rem;
    }
}