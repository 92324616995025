.alert-custom {
    position: fixed;
    top: 20px;
    //z-index: -1500;
    z-index: 1001;
    left: 50%;
    min-width: 30vw;
    transform: translateX(-50%);
    min-height:80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-align: center;
    i {
        font-size: 35px;
        color: #000 !important;
        fill:#000;
        margin-right: 10px;
    }
    &.alert-success i{
        color: #0cac0c !important;
    }
}
