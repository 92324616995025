.dialog {
  width: 90%;
  max-width: 800px;
  min-height: 500px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close {
    position: absolute;
    right: 5px;
    top:5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }

  .dialog-header {
    position: relative;
    background: #f0f0f0;
    height: $height-header;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    h1,h2,h3,h4,h5,h6 {
      font-size: 1.4rem;
      margin: 0;
      padding: 0;
    }
  }

  .dialog-body {
    position: relative;
    padding: 15px;
    display: block;
    text-align: left;
    .title {
      display: block;
      width: 100%;
      padding: 15px 15px 0px 15px;
      margin-bottom: 10px;
      border-bottom: none;
      &:not(:first-child){
        margin-top: 20px;
      }
      h1,h2,h3,h4,h5,h6 {
        font-size: 1.1rem;
        color:$bg-header;
      }
    }

  }

  .dialog-footer {
    background: transparent;
    padding: 15px;
    border-top: 1px #e0e0e0 solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

  }
}
