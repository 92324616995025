$button-outline: 2px;

$button-color_black_bg: #000;
$button-color_black_fg: #fff;

$button-color_dark_bg: #555;
$button-color_dark_fg: #fff;

$button-color_white_bg: #fff;
$button-color_white_fg: #000;

$button-color_light_bg: #ccc;
$button-color_light_fg: #000;

$button-color_primary_bg: #017cf8;
$button-color_primary_fg: #fff;

$button-color_link_bg: #0143a6;
$button-color_link_fg: #fff;

$button-color_info_bg: #8edcf6;
$button-color_info_fg: #000;

$button-color_success_bg: #46a049;
$button-color_success_fg: #000;

$button-color_warning_bg: #f8b317;
$button-color_warning_fg: #000;

$button-color_danger_bg: #ff0000;
$button-color_danger_fg: #fff;


@mixin button-filled($color-bg, $color-fg) {
    background-color: $color-bg;
    color: $color-fg !important;
    &:hover {
        background-color: darken($color-bg, 15%);
        //border: 2px solid darken($color-bg, 15%);
        //box-shadow: 0 0 0 5px rgba($color-bg, 0.5);
        //transform: scale(1.1);
        text-decoration: none;
    }
    &:focus, &:active {
        box-shadow: 0 0 0 5px rgba($color-bg, 0.5);
        outline: none;
    }
}

@mixin button-outline($color-bg, $color-fg) {
    border-color: $color-bg;
    color: $color-bg !important;
    background-color: transparent;
    transition: background-color 0.3s linear;
    &:hover {
        background-color: $color-bg;
        color: $color-fg !important;
    }
    &:focus, &:active {
        box-shadow: 0 0 0 5px rgba($color-bg, 0.5);
        outline: none;
    }

}

.button {
    position: relative;
    cursor: pointer;
    display: inline-block;
    outline: 0;
    white-space: nowrap;
    padding: 0 18px;
    font-size: .8rem;
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    background-color: $button-color_dark_bg;
    color: $button-color_dark_fg !important;
    transition: background-color 0.3s linear;
    &:hover {
        background-color: darken($button-color_dark_bg, 15%);
    }


    &.is-rounded {
        border-radius: 20px;
    }

    &.is-outline {
        height: 40px;
        line-height: 40px - (2 * $button-outline);
        text-shadow: none;
        background: none;
        color: $button-color_dark_bg;
        border: $button-outline $button-color_dark_bg solid;
        trasition: background-color 0.3s linear;

        &:hover {
            background-color: $button-color_dark_bg;
            color: $button-color_dark_fg;
        }

    }

    // black
    &.is-black {
        @include button-filled($button-color_black_bg, $button-color_black_fg);
    }
    &.is-black.is-outline {
        @include button-outline($button-color_black_bg, $button-color_black_fg);
    }

    // dark
    &.is-dark {
        @include button-filled($button-color_dark_bg, $button-color_dark_fg);
    }
    &.is-dark.is-outline {
        @include button-outline($button-color_dark_bg, $button-color_dark_fg);
    }

    // white
    &.is-light {
        @include button-filled($button-color_light_bg, $button-color_light_fg);
    }
    &.is-light.is-outline {
        @include button-outline($button-color_light_bg, $button-color_light_fg);
    }

    // white
    &.is-white {
        @include button-filled($button-color_white_bg, $button-color_white_fg);
    }
    &.is-white.is-outline {
        @include button-outline($button-color_white_bg, $button-color_white_fg);
    }

    // primary
    &.is-primary {
        @include button-filled($button-color_primary_bg, $button-color_primary_fg);
    }
    &.is-primary.is-outline {
        @include button-outline($button-color_primary_bg, $button-color_primary_fg);
    }

    // link
    &.is-link {
        @include button-filled($button-color_link_bg, $button-color_link_fg);
    }
    &.is-link.is-outline {
        @include button-outline($button-color_link_bg, $button-color_link_fg);
    }

    // info
    &.is-info {
        @include button-filled($button-color_info_bg, $button-color_info_fg);
    }
    &.is-info.is-outline {
        @include button-outline($button-color_info_bg, $button-color_info_fg);
    }

    // success
    &.is-success {
        @include button-filled($button-color_success_bg, $button-color_success_fg);
    }
    &.is-success.is-outline {
        @include button-outline($button-color_success_bg, $button-color_success_fg);
    }

    // warning
    &.is-warning {
        @include button-filled($button-color_warning_bg, $button-color_warning_fg);
    }
    &.is-warning.is-outline {
        @include button-outline($button-color_warning_bg, $button-color_warning_fg);
    }

    // danger
    &.is-danger {
        @include button-filled($button-color_danger_bg, $button-color_danger_fg);
    }
    &.is-danger.is-outline {
        @include button-outline($button-color_danger_bg, $button-color_danger_fg);
    }

    &.is-custom {
        @include button-filled($bg-header, #fff);
    }
    &.is-custom.is-outline {
        @include button-outline($bg-header, #fff);
    }
}
