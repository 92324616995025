// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

//backgrounds
$bg-main : #f9f9ff;

$bg-header : #E71D36;
$bg-header : #1A936F;
$bg-header : #FFC857;
$bg-header : #FB3640;
$bg-header : #A01A7D;
$bg-header : #FF9F1C;
$bg-header : #0F62A0;
$bg-header : #EF5D60;
$bg-header : #890620;
$bg-header : #8DA9C4;
$bg-header : #006989;

//$bg-header:rgba(255,255,255,0.5);
$bg-navigation: #fff;
$bg-navigation: #f9f9f9;
$bg-navitem-hover: #6d747950;
$color-navigation: #666;

$bg-footer: #444;


// foregrounds

// layout
$height-header :40px;
$height-title:60px;
$width-navigation : 250px;
$height-footer:40px;


//tables
$color-border-cell: #ffffff;
$bg-1-table: #fff;
$bg-2-table: #f3f3f3;
$border-top-height : 6px;
$color-border-top: #999;
$color-sorter:#f4fb88;



