header {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: $height-header;
    background: $bg-header;
    color:#fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 15px 0 ($height-header + 15px);
    //box-shadow: 0 2px 4px rgba(50,50,50,.5);
    z-index: 1001;
    font-size: 1.2rem;

    .actions {
        font-size: 1rem;
        a {
            color:#fff;
        }
    }

    .hamburger {
        position: absolute;
        background: #444;
        display: block;
        left: 0px;
        top: 0px;
        width: $height-header;
        height: $height-header;
        font-weight: normal;
        cursor: pointer;
        .show, .hide {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 1s;
        }
        .hide {
            opacity: 0;
            body.nav-active & {
                opacity: 1;
            }
        }
        .show {
            opacity: 1;
            body.nav-active & {
                opacity: 0;
            }
        }

    }

    .language-selector {
        margin: 0;
        margin-left:auto;
        margin-right: 15px;
        padding: 0;
        display: flex;
        align-items: center;
        a {
            display: inline-flex;
            width: 25px;
            height: 25px;
            align-items: center;
            justify-content: center;
            margin-right: 3px;
            color:#999;
            font-size: 0.9rem;
            &.active,
            &:hover {
                color: #fff;
            }
        }
    }

    .actions {
        margin-left: 15px;
    }
}



