.left-side {
    position: fixed;
    top: $height-header;
    left: 0;
    bottom: 0;
    width: $width-navigation;
    transform: translateX(-100%);
    background: $bg-navigation;
    border-right: 1px #ddd solid;
    transition: transform 0.2s;
    overflow-y: auto;
    z-index: 1000;

    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-track {
        background: #e5e5e5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        //outline: 1px solid slategrey;
    }

    body.nav-active & {
        transform: translateX(0);
    }
    .header {
        height: $height-title;
        background: #fff;
        border-bottom: 1px #eee solid;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        top:0px;
    }

    .navigation {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-bottom: $height-footer;
        .text {
            display: inline-block;
            margin-left: 8px;
            font-size: .9rem;
        }
        .more {
            width: 20px;
            height: 20px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 10px;
            transition:transform .2s;
        }
        li.menu-item {
            display: block;
        }
        li.menu-item.active .more {
            transform: rotate(90deg);
        }
        a {
            display: flex;
            justify-items: center;
            align-items: center;
            color: $color-navigation !important;
            &:hover {
                background: $bg-navitem-hover;
                text-decoration: none;
            }

        }

        & > li {
            border-bottom: 2px #ddd solid;
            border-top: 1px #fff solid;
            border-bottom: 1px #bbb solid;
            a {
                color: #333;
                padding: 12px 15px;
            }
        }
    }
    .submenu {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: none;

        li {
            a {
                padding: 8px 30px;
            }
        }

    }

    .menu-item.active .submenu {
        display:block;
    }

    .menu-separator {
        border-bottom: 1px #e0e0e0 solid;
        //padding-top: 5px;
        //margin-bottom: 5px;
    }


}





