.btn-back {
    a {
        width: $height-title - 15;
        height: $height-title - 15;
        font-size: 2rem;
        color: $bg-header;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;

        &:hover {
            text-decoration: none;
            background: #f0f0f0;
        }
    }
}

/*
.form-check-input {
    transform: scale(1.3);
    margin-top: 5px;
}
*/

.form-check {
    padding-left: 0;
    margin-bottom: 1rem;
}


/* Hide the checkbox/radio. We will simulate it later using label */
input[type=checkbox],
input[type=radio] {
    opacity: 0;
    position: absolute;
}

/* Some basic styling for alignment and more */
input[type=checkbox],
input[type=checkbox]+label,
input[type=radio],
input[type=radio]+label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    margin-right: 15px;
}

/* CSS Pseudo class "before" to show checkbox/radio box. Will be attached to every label adjacent to input checkbox.*/
input[type=checkbox]+label:before,
input[type=radio]+label:before {
    font-family: 'FontAwesome';
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    text-align: center;
    font-size: 12px;
    //padding: 4px 0;
    color: #e4efe5;
    transition: color .4s;
    line-height: 20px;
    background: #fff !important;
    border: 2px #e0e0e0 solid !important;
    border: 2px $bg-header solid !important;
    padding: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

/* Tick inside checkbox. I am using Font-awesome's tick, but you can use plain unicode tick */
/* or any other symbol you want. */
input[type=checkbox]+label:before {
    content: '\f00c';
    color: transparent;
    border-radius: 4px;
}

/* Circle inside radio circle. Border-radius to make it a circle. */
input[type=radio]+label:before {
    content: "";
    border-radius: 50%;
    color: #fff;
    //width: 18px;
    //height: 18px;
    border: 2px $bg-header solid !important;
    //box-shadow: inset 0px 0px 0px 3px #fff, inset 0px 0px 0px 10px $bg-header ;    
}

/* Styles when checkbox/radio is checked */
input:checked+label:before {
    background: $bg-header !important;
    color: #FFF;
}

input[type=radio]:checked+label:before {
    box-shadow: inset 0px 0px 0px 3px #fff;
}

/* Styles when checkbox/radio is disabled  */
input[type=checkbox][disabled]+label:before,
input[type=radio][disabled]+label:before {
    color: #d0d0d0;
    background: #bfbfbf;
    border: 1px solid #656b64;
}


.card-form .card-body{
    max-width: 1100px;
}

.card-form.wide .card-body{
    max-width: none;
}